import React from 'react';

const HeroImage = () => (
  <svg
    id="fafb0f89-a72a-431c-9009-ab2c777222ec"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    // width="921.86066"
    // height="658.97432"
    viewBox="0 0 921.86066 658.97432"
  >
    <polygon
      points="921.466 658.974 347.385 658.974 347.385 656.793 921.861 656.793 921.466 658.974"
      fill="#3f3d56"
    />
    <path
      d="M143.77062,120.51284a4.70648,4.70648,0,0,0-4.701,4.701V395.98831a4.70648,4.70648,0,0,0,4.701,4.70095H680.61873a4.70648,4.70648,0,0,0,4.70094-4.70095V125.21379a4.70648,4.70648,0,0,0-4.70094-4.701Z"
      transform="translate(-139.06967 -120.51284)"
      fill="#e6e6e6"
    />
    <path
      d="M151.29213,128.03436a4.70647,4.70647,0,0,0-4.70094,4.70094V388.4668a4.70648,4.70648,0,0,0,4.70094,4.701H673.09721a4.70648,4.70648,0,0,0,4.701-4.701V132.7353a4.70647,4.70647,0,0,0-4.701-4.70094Z"
      transform="translate(-139.06967 -120.51284)"
      fill="#fff"
    />
    <path
      d="M307.83366,292.56749H196.89132a6.58872,6.58872,0,0,1-6.58133-6.58133V175.04382a6.58871,6.58871,0,0,1,6.58133-6.58132H307.83366a6.58871,6.58871,0,0,1,6.58132,6.58132V285.98616A6.58872,6.58872,0,0,1,307.83366,292.56749ZM196.89132,170.34288a4.70647,4.70647,0,0,0-4.701,4.70094V285.98616a4.70648,4.70648,0,0,0,4.701,4.701H307.83366a4.70648,4.70648,0,0,0,4.70094-4.701V175.04382a4.70647,4.70647,0,0,0-4.70094-4.70094Z"
      transform="translate(-139.06967 -120.51284)"
      fill="#3f3d56"
    />
    <path
      d="M467.66584,292.56749H356.7235a6.58872,6.58872,0,0,1-6.58132-6.58133V175.04382a6.58871,6.58871,0,0,1,6.58132-6.58132H467.66584a6.58872,6.58872,0,0,1,6.58133,6.58132V285.98616A6.58873,6.58873,0,0,1,467.66584,292.56749ZM356.7235,170.34288a4.70647,4.70647,0,0,0-4.70095,4.70094V285.98616a4.70648,4.70648,0,0,0,4.70095,4.701H467.66584a4.70648,4.70648,0,0,0,4.70095-4.701V175.04382a4.70647,4.70647,0,0,0-4.70095-4.70094Z"
      transform="translate(-139.06967 -120.51284)"
      fill="#e6e6e6"
    />
    <path
      d="M365.18521,178.80458a4.70648,4.70648,0,0,0-4.70095,4.701v94.01893a4.70648,4.70648,0,0,0,4.70095,4.701h94.01893a4.70648,4.70648,0,0,0,4.70095-4.701V183.50553a4.70648,4.70648,0,0,0-4.70095-4.701Z"
      transform="translate(-139.06967 -120.51284)"
      fill="#e6e6e6"
    />
    <path
      d="M205.82311,178.80458a4.70648,4.70648,0,0,0-4.70094,4.701v94.01893a4.70648,4.70648,0,0,0,4.70094,4.701H299.842a4.70648,4.70648,0,0,0,4.70094-4.701V183.50553a4.70648,4.70648,0,0,0-4.70094-4.701Z"
      transform="translate(-139.06967 -120.51284)"
      fill="#3f3d56"
    />
    <path
      d="M524.5473,178.80458a4.70648,4.70648,0,0,0-4.70095,4.701v94.01893a4.70648,4.70648,0,0,0,4.70095,4.701h94.01893a4.70648,4.70648,0,0,0,4.701-4.701V183.50553a4.70648,4.70648,0,0,0-4.701-4.701Z"
      transform="translate(-139.06967 -120.51284)"
      fill="#4199e1"
    />
    <path
      d="M627.498,292.56749H516.55569a6.589,6.589,0,0,1-6.58133-6.58133V175.04382a6.589,6.589,0,0,1,6.58133-6.58132H627.498a6.589,6.589,0,0,1,6.58132,6.58132V285.98616A6.589,6.589,0,0,1,627.498,292.56749ZM516.55569,170.34288a4.70647,4.70647,0,0,0-4.701,4.70094V285.98616a4.70648,4.70648,0,0,0,4.701,4.701H627.498a4.70647,4.70647,0,0,0,4.70094-4.701V175.04382a4.70647,4.70647,0,0,0-4.70094-4.70094Z"
      transform="translate(-139.06967 -120.51284)"
      fill="#4199e1"
    />
    <circle cx="228.46601" cy="207.78184" r="15.98322" fill="#e6e6e6" opacity="0.8" />
    <circle cx="318.72418" cy="207.78184" r="15.98322" fill="#e6e6e6" opacity="0.4" />
    <circle cx="273.59509" cy="207.78184" r="15.98322" fill="#e6e6e6" opacity="0.6" />
    <circle cx="68.63382" cy="207.78184" r="15.98322" fill="#3f3d56" opacity="0.8" />
    <circle cx="158.892" cy="207.78184" r="15.98322" fill="#3f3d56" opacity="0.4" />
    <circle cx="113.76291" cy="207.78184" r="15.98322" fill="#3f3d56" opacity="0.6" />
    <circle cx="388.29819" cy="207.78184" r="15.98322" fill="#4199e1" opacity="0.8" />
    <circle cx="478.55637" cy="207.78184" r="15.98322" fill="#4199e1" opacity="0.4" />
    <circle cx="433.42728" cy="207.78184" r="15.98322" fill="#4199e1" opacity="0.6" />
    <polygon
      points="422.908 140.352 400.367 111.371 413.475 101.175 424.148 114.897 460.203 76.838 472.26 88.26 422.908 140.352"
      fill="#fff"
    />
    <path
      d="M773.62083,437.64752a3.17255,3.17255,0,0,0-3.97133-.3909l-24.25935,15.80083a3.19148,3.19148,0,0,0-.5101,4.93545l61.694,61.42675a3.19121,3.19121,0,0,0,4.5466-.04391l20.218-20.92546a3.19827,3.19827,0,0,0-.06531-4.50042Z"
      transform="translate(-139.06967 -120.51284)"
      fill="#3f3d56"
    />
    <path
      d="M750.16926,454.74763l2.51058-1.6007a2.392,2.392,0,0,0,3.44276,3.14071l16.24627-11.16931a2.39059,2.39059,0,0,0-.63206-4.25033l1.29346-.82469a.87035.87035,0,0,1,1.087.12213l54.65363,53.70972a.87033.87033,0,0,1-.022,1.245L808.9,514.46061a.87034.87034,0,0,1-1.21147-.01682l-57.66577-58.34586A.87034.87034,0,0,1,750.16926,454.74763Z"
      transform="translate(-139.06967 -120.51284)"
      fill="#fff"
    />
    <polygon
      points="674.178 361.503 657.412 377.311 617.533 337.91 637.772 324.737 674.178 361.503"
      fill="#4199e1"
    />
    <path
      d="M758.15282,468.147a.58113.58113,0,0,0,0-.82054l-3.48744-3.48737a.59433.59433,0,0,0-.82061,0,.58111.58111,0,0,0,0,.82054l3.48743,3.48737a.59434.59434,0,0,0,.82062,0Z"
      transform="translate(-139.06967 -120.51284)"
      fill="#e6e6e6"
    />
    <path
      d="M764.02758,473.20118l-3.48743-3.48743a.58026.58026,0,0,0-.82062.82061l3.48744,3.48744a.58026.58026,0,0,0,.82061-.82062Z"
      transform="translate(-139.06967 -120.51284)"
      fill="#e6e6e6"
    />
    <circle cx="673.79107" cy="375.07159" r="3.91651" fill="#4199e1" />
    <path
      d="M926.37474,503.60852l-3.01867,14.716a12.18079,12.18079,0,0,0,15.98635,13.934h0a12.18079,12.18079,0,0,0,7.6249-14.94679l-4.06022-13.70325,36.73867-107.46058H948.41794Z"
      transform="translate(-139.06967 -120.51284)"
      fill="#ffb8b8"
    />
    <circle cx="754.24028" cy="140.62051" r="28.47246" fill="#ffb8b8" />
    <path
      d="M888.71762,311.649l37.65712-4.59233c-8.639-10.83179-11.90542-22.14119-9.18466-33.98326l-33.98326,3.67386Z"
      transform="translate(-139.06967 -120.51284)"
      fill="#ffb8b8"
    />
    <path
      d="M841.87582,470.54373l89.09126-20.20627c19.04291-14.81005,31.41371-44.187,40.96968-79.70975,6.96408-25.88784-5.54817-53.44852-30.13975-64.12248q-.88191-.38278-1.78173-.7369l-20.0698-7.89632-33.0648,2.7554-11.7968,3.21731a13.37974,13.37974,0,0,0-9.70958,10.91258C849.51439,372.95248,839.0596,426.92809,841.87582,470.54373Z"
      transform="translate(-139.06967 -120.51284)"
      fill="#4199e1"
    />
    <polygon
      points="804.756 293.086 844.25 293.086 831.391 215.935 804.756 218.69 804.756 293.086"
      fill="#4199e1"
    />
    <path
      d="M810.648,747.92063h23.88013l50.05643-175.88635,19.31672,175.88635h27.9843l11.0216-281.96924c-4.20631-21.63374-4.11639-47.41662-1.83693-75.31425,19.69536-13.78841,12.48277-46.52006,14.66271-79.40614a1.2543,1.2543,0,0,0-.28514-.88193l-12.69212-6.597a1.241,1.241,0,0,0-2.17568.90909c-.32279,22.99357,5.87607,50.33886-4.1021,68.52507-10.841-37.05832-24.97058-68.17389-52.47627-75.12112a3.04666,3.04666,0,0,0-1.57958.03109l-5.1217,1.46334a3.04248,3.04248,0,0,0-.7321,5.5268c25.16333,15.54618,41.42756,41.57585,42.45878,85.55076-19.579,27.7089-38.64592,44.11081-72.96944,64.28253a12.79729,12.79729,0,0,0-6.22934,9.72784Z"
      transform="translate(-139.06967 -120.51284)"
      fill="#2f2e41"
    />
    <path
      d="M897,287l20.19008-3.82345,12.01141-20.51949a27.28017,27.28017,0,0,0-3.19388-31.95039l0,0a27.28014,27.28014,0,0,0-24.2073-8.837l-26.16686,3.73812a17.27882,17.27882,0,0,0-14.64407,19.66832l17.62512,2.99869Z"
      transform="translate(-139.06967 -120.51284)"
      fill="#2f2e41"
    />
    <path
      d="M782.68968,778.68926h42.06161a13.83748,13.83748,0,0,0,13.69264-15.83432l-2.64032-18.10509-19.09093-1.41415-37.9376,17.745a9.2394,9.2394,0,0,0-5.28318,9.24512h0A9.2394,9.2394,0,0,0,782.68968,778.68926Z"
      transform="translate(-139.06967 -120.51284)"
      fill="#2f2e41"
    />
    <path
      d="M878.2102,778.68926h42.06161a13.83747,13.83747,0,0,0,13.69264-15.83432l-2.64032-18.10509-19.09092-1.41415-37.9376,17.745a9.2394,9.2394,0,0,0-5.28319,9.24512h0A9.2394,9.2394,0,0,0,878.2102,778.68926Z"
      transform="translate(-139.06967 -120.51284)"
      fill="#2f2e41"
    />
    <path
      d="M798.26791,469.807l-18.19668,6.32534a12.86789,12.86789,0,0,0-3.725,22.27275l0,0a12.86789,12.86789,0,0,0,14.91417.70227l16.58555-10.67449L864.478,430.8683l8.77662-67.66092a51.52234,51.52234,0,0,0-18.17246-46.25983l0,0L836.614,420.0152Z"
      transform="translate(-139.06967 -120.51284)"
      fill="#ffb9b9"
    />
    <path
      d="M837.28349,399.8218H877.696l-.91847-96.439h0a46.5784,46.5784,0,0,0-35.19077,42.2822Z"
      transform="translate(-139.06967 -120.51284)"
      fill="#4199e1"
    />
    <ellipse cx="745.93033" cy="144.48716" rx="5" ry="6" fill="#ffb8b8" />
  </svg>
);

export default HeroImage;