import React from 'react';

const HeroImage = () => (
  <svg
    id="ab8ae2a1-21db-4159-bb67-e2c62dcc19dc"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    // width="867.93857"
    // height="588.5"
    viewBox="0 0 867.93857 588.5"
  >
    <path
      d="M925.055,325.47994H809.02141c-15.70477-42.51261-22.52583-82.0414,0-111.71322A58.01672,58.01672,0,0,1,867.03813,155.75h.00012a57.94434,57.94434,0,0,1,47.293,24.40356c9.16871,12.877,11.00592,29.47655,5.74836,44.38425C910.7093,251.10718,916.43032,287.36181,925.055,325.47994Z"
      transform="translate(-166.03071 -155.75)"
      fill="#2f2e41"
    />
    <path
      d="M986.33673,744.25h0a15.70205,15.70205,0,0,1-15.0077-11.0843l-4.78036-15.53616a23.1288,23.1288,0,0,1-.61965-11.10129l3.25577-17.209h22.83639l9.47609,35.14049A15.70206,15.70206,0,0,1,986.33673,744.25Z"
      transform="translate(-166.03071 -155.75)"
      fill="#2f2e41"
    />
    <path
      d="M819.952,744.25h0a15.70206,15.70206,0,0,0,15.0077-11.0843l4.78035-15.53616a23.12867,23.12867,0,0,0,.61965-11.10129l-3.25576-17.209H814.26761l-9.47609,35.14049A15.70206,15.70206,0,0,0,819.952,744.25Z"
      transform="translate(-166.03071 -155.75)"
      fill="#2f2e41"
    />
    <path
      d="M993.87275,691.17082h-25.3052L858.706,482.55729,838.438,691.17082H816.11921c-11.45007-131.36776-39.30814-256.49986,0-303.6623l91.96277,8.6408Z"
      transform="translate(-166.03071 -155.75)"
      fill="#2f2e41"
    />
    <circle cx="697.92148" cy="59.86838" r="30.85999" fill="#9f616a" />
    <path
      d="M907.46478,334.42934H838.9556l-11.7268-54.31358c22.75118-6.72118,28.17526-22.35365,23.4536-43.204l37.032-2.4688c2.09887,16.7954,5.93889,32.4559,29.62559,35.18039Z"
      transform="translate(-166.03071 -155.75)"
      fill="#9f616a"
    />
    <path
      d="M907.46478,402.32132c-38.8692,14.75815-70.48976,14.29779-92.58-6.172,9.96854-32.87311,10.56517-73.35384,6.172-117.88516l12.344-5.5548,4.3204,39.50079,61.10278-3.086,13.5784-43.204,13.57839,3.086C927.02154,322.57291,923.81334,370.76229,907.46478,402.32132Z"
      transform="translate(-166.03071 -155.75)"
      fill="#e6e6e6"
    />
    <path
      d="M943.26236,468.97889l7.25707,23.58547a12.77544,12.77544,0,0,1-12.2105,16.53252h0a12.77544,12.77544,0,0,1-12.70628-14.103l2.84695-27.24942-11.7268-92.58,8.6408-40.73519h21.602Z"
      transform="translate(-166.03071 -155.75)"
      fill="#9f616a"
    />
    <path
      d="M770.39935,464.07329,757.64619,485.199a12.77544,12.77544,0,0,0,7.843,18.99758h0a12.77544,12.77544,0,0,0,15.74359-10.60578l3.83732-27.12768,33.79955-86.98367,6.81613-41.65553-26.29294-5.19141Z"
      transform="translate(-166.03071 -155.75)"
      fill="#9f616a"
    />
    <path
      d="M962.39556,339.36694l-40.73519-.6172v-70.978l6.27186.91465a29.98433,29.98433,0,0,1,25.52968,26.906Z"
      transform="translate(-166.03071 -155.75)"
      fill="#e6e6e6"
    />
    <path
      d="M788.34522,346.77334l40.73518-.6172v-70.978l-6.27185.91465a29.98433,29.98433,0,0,0-25.52968,26.906Z"
      transform="translate(-166.03071 -155.75)"
      fill="#e6e6e6"
    />
    <path
      d="M892.34338,200.80558c-13.10909,7.41716-38.302,10.8099-56.78238,5.5548L829.389,174.266h61.10278Z"
      transform="translate(-166.03071 -155.75)"
      fill="#2f2e41"
    />
    <rect x="40" y="426" width="201" height="162" fill="#e6e6e6" />
    <rect x="123.5" y="541" width="34" height="46" fill="#4199e1" />
    <path
      d="M428.93606,716.81743l-3.47308-2.90485.25671,1.814S426.94693,716.1118,428.93606,716.81743Z"
      transform="translate(-166.03071 -155.75)"
      fill="none"
    />
    <polygon
      points="488.026 430.784 493.376 416.518 543.307 387.986 577.189 380.853 488.026 430.784"
      fill="#4199e1"
    />
    <polygon
      points="304.352 430.784 299.002 416.518 249.071 387.986 215.189 380.853 304.352 430.784"
      fill="#4199e1"
    />
    <polygon
      points="491.593 434.35 300.785 434.35 284.736 359.454 507.642 359.454 491.593 434.35"
      fill="#4199e1"
    />
    <polygon
      points="491.593 434.35 300.785 434.35 284.736 359.454 507.642 359.454 491.593 434.35"
      opacity="0.1"
      // style="isolation:isolate"
    />
    <rect x="299.89341" y="429.89163" width="192.59109" height="158.10837" fill="#4199e1" />
    <rect x="379.18898" y="540.97662" width="34" height="46" fill="#3f3d56" />
    <polygon
      points="867.543 588.19 0 588.19 0 586.008 867.939 586.008 867.543 588.19"
      fill="#3f3d56"
    />
    <rect x="356.18896" y="462.25" width="80" height="25" fill="#fff" />
    <rect x="99.96929" y="462.25" width="80" height="25" fill="#fff" />
  </svg>
);

export default HeroImage;