import React from 'react';
import Button from '../components/Button';

const ContactForm = () => (
  <>
    <form name="contact" method="POST" data-netlify="true" className="bg-white shadow-md rounded p-4">
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" for="name"> Name </label>
        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="name" type="text" placeholder="Name" />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" for="email"> Email </label>
        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="email" type="email" placeholder="Email" />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" for="message"> Message </label>
        <textarea className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="message" name="message" placeholder="Message" >
          </textarea>
      </div>
      <div className="flex items-center justify-between">
        <Button size="md" type="submit">Get your free consultation</Button>
      </div>
    </form>
  </>
);

export default ContactForm;