import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { graphql } from 'gatsby';
import Button from '../components/Button';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import ContactForm from '../components/ContactForm';

import SortCollaboration from '../svg/SortCollaboration';
import Delivered from '../svg/Delivered';
import Sort from '../svg/Sort';

import bgimage from '../images/equip5.jpg';
import bgimage2 from '../images/equip2.jpg';
import ben1 from '../images/equip1.jpg';
import ben2 from '../images/equip3.jpg';
import img1 from '../images/surgical-equipment.jpg';
import img2 from '../images/microscope.jpg';
import img3 from '../images/or.jpg';
import img4 from '../images/hospital-chair.jpg';
import listingRationale from '../data/listingRationale';
import ChartRationale from '../components/ChartRationale';

export default () => (
  <Layout className="top-0 relative ">
    <section
      className="object-cover bg-right text-white  "
      style={{ backgroundImage: `url('${bgimage}')` }}
    >
      <div className="bg-gradient-to-br from-gray-900 via-gray-900 py-40">
        <div className="container mx-auto px-4 lg:flex">
          <div className=" lg:w-1/2 ">
            <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
              Innovative Inventory Solutions
            </h1>
            <p className="text-xl lg:text-2xl mt-6 font-light">
              We help hospitals free up capital and reclaim storage space. Our process identifies
              your unused equipment and matches it up with qualified buyers.
            </p>
            <p className="mt-8 md:mt-12">
              <AnchorLink href="#contact">
                <Button size="lg">Contact Us</Button>
              </AnchorLink>
            </p>
            <p className="mt-4 text-gray-200">Free Consultation</p>
          </div>
        </div>
      </div>
    </section>

    <section id="program" className="py-20 lg:pb-40 lg:pt-48">
      <div className="container mx-auto">
        <div className="text-center">
          <h6 className="text-xl font-bold text-primary">SUSTAIN MED</h6>
          <h2 className="text-4xl lg:text-6xl font-semibold ">Program</h2>
        </div>

        <div className="flex gap-8 space-x-12 flex-wrap">
          <div className="lg:w-5/12">
            <div className="flex flex-col sm:-mx-3 mt-12 space-y-12 gap-4">
              <div className="flex-1 px-3">
                <div className="w-24 h-20 pb-8">
                  <Delivered />
                </div>
                <p className="font-semibold text-xl ">Identify Unused Equipment</p>
                <p className="mt-4">
                  We'll work with your team to identify unused equipment and document it in our
                  inventory management software.
                </p>
              </div>
              <div className="flex-1 px-3">
                <div className="w-24 h-20 pb-8">
                  <SortCollaboration />
                </div>
                <p className="font-semibold text-xl">Communicate with Stakeholders</p>
                <p className="mt-4">
                  Stakeholders have full visibility into equipment management opportunities through
                  our process.
                </p>
              </div>
              <div className="flex-1 px-3">
                <div className="w-24 h-20 pb-8">
                  <Sort />
                </div>
                <p className="font-semibold text-xl">Free up Capital</p>
                <p className="mt-4">
                  We'll find buyers for your unused equipment and facilitate the process, providing
                  extra budget room to your department.
                </p>
              </div>
            </div>
          </div>
          <div className="lg:w-5/12 justify-center flex items-center">
            <div className="mt-10 lg:-mx-4 relative relative-20 lg:mt-0 lg:col-start-1">
              <div className="relative space-y-4">
                <div className="flex items-end justify-center lg:justify-start space-x-4">
                  <img
                    className="rounded-lg shadow-lg w-32 md:w-56"
                    width="200"
                    src={img1}
                    alt="1"
                  />
                  <img
                    className="rounded-lg shadow-lg w-40 md:w-64"
                    width="260"
                    src={img2}
                    alt="2"
                  />
                </div>
                <div className="flex items-start justify-center lg:justify-start space-x-4 ml-12">
                  <img
                    className="rounded-lg shadow-lg w-24 md:w-40"
                    width="170"
                    src={img3}
                    alt="3"
                  />
                  <img
                    className="rounded-lg shadow-lg w-32 md:w-56"
                    width="200"
                    src={img4}
                    alt="4"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="benefits" className="py-20 lg:pt-32 bg-gray-900 text-white">
      <div className="container mx-auto text-center">
        <LabelText className="text-white">Our customers get results</LabelText>
        <div className="flex flex-col sm:flex-row mt-8 lg:px-24">
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="Better Budget" secondaryText="Buy and Sell Previously Owned" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="More Storage" secondaryText="Free Up Storage Space" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox
              primaryText="Fully Compliant"
              secondaryText="Legal and Accounting Compliance"
            />
          </div>
        </div>
      </div>
    </section>

    <section id="services" className="py-20 lg:pt-32 container mx-auto p-8">
      <div className="lg:w-2/3">
        <h6 className="text-xl font-bold text-primary">SUSTAIN MED</h6>
        <h1 className="text-3xl lg:text-4xl  xl:text-5xl font-bold leading-none">Services</h1>
        <p className="text-xl lg:text-2xl mt-6 font-light">
          We will partner with you to help the ongoing management of your inventory.
        </p>
      </div>
    </section>
    <div className="">
      <SplitSection
        id="pre-owned"
        reverseOrder
        primarySlot={
          <div className=" flex flex-wrap items-center justify-center h-full">
            <div>
              <h3 className="text-3xl font-semibold leading-tight">Sell your Unused Equipment</h3>
              <p className="mt-8 text-xl font-light leading-relaxed">
                Our team faciliates sales of your unused equipment to other Canadian hospitals,
                freeing up space and budgets.
              </p>
            </div>
          </div>
        }
        secondarySlot={
          <div className="py-16">
            <ChartRationale data={listingRationale} />
          </div>
        }
      />
    </div>
    <SplitSection
      primarySlot={
        <div className=" flex flex-wrap items-center justify-center h-full">
          <div>
            <h3 className="text-3xl font-semibold leading-tight">Acquire Equipment</h3>
            <p className="mt-8 text-xl font-light leading-relaxed">
              Using our network, we help you acquire discounted equipment from Canadian Hospitals.
            </p>
          </div>
        </div>
      }
      secondarySlot={<img className="" src={ben1} alt="1" />}
    />
    <SplitSection
      reverseOrder
      primarySlot={
        <div className=" flex flex-wrap items-center justify-center h-full">
          <div>
            <h3 className="text-3xl font-semibold leading-tight">
              Legal and Accounting Compliance
            </h3>
            <p className="mt-8 text-xl font-light leading-relaxed">
              Our team will work with your team to facilitate all compliance aspects of each
              transaction.
            </p>
          </div>
        </div>
      }
      secondarySlot={<img className="" src={ben2} alt="1" />}
    />

    <section
      id="contact"
      className="object-cover bg-right text-white  "
      style={{ backgroundImage: `url('${bgimage2}')` }}
    >
      <div className="bg-gradient-to-tl from-gray-900 via-gray-900">
        <div
          id="contact"
          className="container mx-auto py-24 rounded-lg text-center flex justify-center"
        >
          <div className="transparent p-8 rounded-lg">
            <h3 className="text-5xl font-semibold">Innovative Inventory Management</h3>
            <p className="mt-8 text-xl font-light">Helping you and your team achieve your goals</p>
            <section className="flex justify-center pt-10 text-left">
              <ContactForm />
            </section>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export const query = graphql`
  query {
    equip: file(relativePath: { eq: "equip1.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 2400, height: 900) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    hospitalBeds: file(relativePath: { eq: "hospital-beds.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 300, height: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    hospitalChair: file(relativePath: { eq: "hospital-chair.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 300, height: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    microscope: file(relativePath: { eq: "microscope.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 300, height: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    or: file(relativePath: { eq: "or.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 300, height: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    surgicalEquipment: file(relativePath: { eq: "surgical-equipment.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 300, height: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;
